import * as React from "react"
import { SVGProps } from "react"
export const BriefcaseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.939 7.313H4.063a.813.813 0 0 0-.812.812v13c0 .449.364.813.812.813h17.875a.813.813 0 0 0 .813-.813v-13a.813.813 0 0 0-.813-.813Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M17.063 7.313V5.688a1.625 1.625 0 0 0-1.625-1.625h-4.876a1.625 1.625 0 0 0-1.624 1.625v1.625M22.75 12.828a19.41 19.41 0 0 1-9.75 2.61 19.41 19.41 0 0 1-9.75-2.609M11.781 12.188h2.438"
    />
  </svg>
)
