import { observer } from 'mobx-react';
import { authStore } from '../../store/authStore';
import { Layout } from '../../components/Layout';
import { Text } from '../../components/Text';
import { ProfileForm } from '../common/ProfileForm';
import React, { useState } from 'react';
import { ChangePasswordForm } from './ChangePasswordForm';
import { Box, CircularProgress, LinearProgress, styled, Typography } from '@mui/material';
import { useRequest } from '../../hooks/useRequest';
import { profileRequest } from './getProfileRequest';
import { getProfileFormik, IProfileValues } from '../common/ProfileForm/profileFormik';
import { updateProfileRequest } from './updateProfileRequest';
import { ProfileInput, getFileInput } from '../../dto';
import { messageStore } from '../../store/messageStore';
import { reloadUser } from '../../utils/users';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { KeyboardArrowRight } from '@mui/icons-material';

type Mode = 'Main' | 'Password';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    gap: 20,
});

const KeyboardArrowRightIcon = styled(KeyboardArrowRight)(({ theme }) => ({
    color: theme.palette.primary.main,
}));

export const ProfilePage = observer(() => {
    const [mode, setMode] = useState<Mode>('Main');
    const history = useHistory();

    const { data: profile, error, loading } = useRequest(profileRequest, undefined);
    const { user } = authStore;

    const submit = async (values: IProfileValues) => {
        const {
            passportMain,
            passportRegistration,
            passportSelfie,
            BIK,
            bankAccount,
            bankName,
            correspondentAccount,
            SNILSScan,
            issuedAt,
            birthDate,
            bankInfoScan,
            ...rest
        } = values;

        const input: ProfileInput = {
            ...rest,
            bankInfo: {
                bankName,
                BIK,
                account: bankAccount,
                correspondentAccount,
            },
            passportScans: {},
        };

        if (issuedAt) input.issuedAt = issuedAt;
        if (birthDate) input.birthDate = birthDate;
        if (passportMain) input.passportScans!.main = await getFileInput(passportMain);
        if (passportRegistration) input.passportScans!.registration = await getFileInput(passportRegistration);
        if (passportSelfie) input.passportScans!.selfie = await getFileInput(passportSelfie);
        if (SNILSScan) input.SNILSScan = await getFileInput(SNILSScan);
        if (bankInfoScan) input.bankInfoScan = await getFileInput(bankInfoScan);

        updateProfileRequest
            .request(input)
            .then(() => messageStore.success('Профиль успешно обновлен'))
            .then(() => history.push('/'))
            .then(reloadUser);
    };

    const download = () => {
        console.error('not implemented yet');
    };

    const disabled = !authStore.canEditProfile;

    if (!user) return <CircularProgress />;

    return (
        <Layout>
            <Breadcrumbs paths={[{ title: 'Главная', link: '/' }, { title: 'Профиль' }]} />
            <Container>
                <Text variant="LargeTitle">Профиль</Text>

                {/* <Box>
                    <Text variant="TitleBold">Мой договор</Text>
                    <Text>Заключен 01.01.2024</Text>
                    <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={download}>
                        <KeyboardArrowRightIcon />
                        <Text color="primary">Скачать</Text>
                    </Box>
                </Box> */}

                {loading && <LinearProgress />}
                {error && <Typography color="error">{error.message}</Typography>}
                {mode === 'Main' ? (
                    profile ? (
                        <Formik {...getProfileFormik(user, profile || null, submit)}>
                            {formik => (
                                <ProfileForm
                                    profile={profile}
                                    onShowPasswordDialog={() => setMode('Password')}
                                    disabled={disabled}
                                    formik={formik}
                                    handleSubmit={() => formik.handleSubmit()}
                                />
                            )}
                        </Formik>
                    ) : null
                ) : (
                    <ChangePasswordForm onComplete={() => setMode('Main')} onBack={() => setMode('Main')} />
                )}
            </Container>
        </Layout>
    );
});
