import * as React from "react"
import { SVGProps } from "react"
export const ChartPieSliceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 13V3.25M21.444 8.125l-16.888 9.75M3.413 14.782A9.765 9.765 0 0 1 9.75 3.805v7.319l-6.337 3.658Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13 3.25a9.75 9.75 0 1 1-8.408 14.69"
    />
  </svg>
)
