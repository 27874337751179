import * as React from "react"
import { SVGProps } from "react"
export const WalletIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4.063 6.5v13a1.625 1.625 0 0 0 1.625 1.625h16.25a.812.812 0 0 0 .812-.813V8.938a.812.812 0 0 0-.813-.812H5.688A1.625 1.625 0 0 1 4.063 6.5Zm0 0a1.625 1.625 0 0 1 1.625-1.625H19.5"
    />
    <path
      fill="#000"
      d="M18.281 15.438a.813.813 0 1 0 0-1.626.813.813 0 0 0 0 1.626Z"
    />
  </svg>
)
