import React, { FC, useState } from 'react';
import { Box, styled, TextField } from '@mui/material';
import { Text } from '../../../components/Text';
import { getDepositStatusText, isOldDepositType } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';
import { formatDate, getMonthsPluralString } from '../../../utils/formatters';
import { Edit, Done, Close } from '@mui/icons-material';
import { editDepositAliasRequest } from './editDepositAliasRequest';
import { errorHandler } from '../../../api/apiHelpers';

type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const EditIcon = styled(Edit)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

const DoneIcon = styled(Done)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

const CloseIcon = styled(Close)(({ theme }) => ({
    cursor: 'pointer',
}));

const DepositName: FC<Props> = ({ deposit }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newAlias, setNewAlias] = useState<string>(deposit.alias || 'Без названия');

    const handleCancel = () => {
        setNewAlias(deposit.alias || 'Без названия');
        setIsEditing(false);
    };

    const handleChangeAlias = async () => {
        try {
            if (newAlias === deposit.alias || newAlias === 'Без названия') {
                setIsEditing(false);
                return;
            }
            await editDepositAliasRequest.request({ alias: newAlias, id: deposit.id });

            setIsEditing(false);
        } catch (error) {
            errorHandler(error);
        }
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            {!isEditing ? (
                <>
                    <Text variant="LargeTitle">{newAlias}</Text>
                    <EditIcon onClick={() => setIsEditing(true)} />
                </>
            ) : (
                <>
                    <TextField variant="standard" value={newAlias} onChange={e => setNewAlias(e.target.value)} />
                    <DoneIcon onClick={handleChangeAlias} />
                    <CloseIcon onClick={handleCancel} />
                </>
            )}
        </Box>
    );
};

export const DepositHeader: FC<Props> = ({ deposit }) => {
    const oldDepositType = isOldDepositType(deposit)
    return (
        <Root>
            <Row>
                <DepositName deposit={deposit} />
            </Row>
            {oldDepositType && <Row>
                <Text variant="Small" color="secondary">
                    {getDepositStatusText(deposit)}
                </Text>
            </Row>}
            <Row>
                <Text variant="Small" color="secondary">
                    {oldDepositType ? 'Вклад' : 'Инвесткопилка'} {deposit.contractNumber} от {formatDate(deposit.contractDate, { month: '2-digit'})} (на {deposit.duration} {getMonthsPluralString(deposit.duration)})
                </Text>
            </Row>
        </Root>
    );
};
